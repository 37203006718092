import { useState, useRef, useEffect } from 'react'
import html2pdf from 'html2pdf.js'
import { SlideUpDialog, RegularButton, CenteredDiv, PrimaryButton } from '@wavetronix/common-components'
import { Card, CardContent, DialogContent } from '@mui/material'
import CertificateTemplate from './CertificateTemplate'

export default function CertificateModal({ open, onClose, certificateInfo }) {
  const [certificate, setCertificate] = useState(null)
  const [previewURL, setPreviewURL] = useState()
  const [loaded, setLoaded] = useState(false)
  const htmlRef = useRef(null)

  function closeModal() {
    onClose()
  }

  useEffect(() => {
    if (certificate) {
      setPreviewURL(URL.createObjectURL(certificate))
    } else {
      setPreviewURL()
      URL.revokeObjectURL(certificate)
    }
  }, [certificate])

  useEffect(() => {
    if (htmlRef.current != null) {
      generatePdf()
    }
  }, [loaded])

  const generatePdf = async () => {
    const content = htmlRef.current

    const allBreaklineElements = content.querySelectorAll('.breakline')

    for (let i = 0; i < allBreaklineElements.length; i++) {
      let newContent = document.createElement('br')
      newContent.style['page-break-before'] = 'always'
      allBreaklineElements[i].replaceWith(newContent)
    }

    if (content) {
      const pdfOptions = {
        margin: 5,
        filename: `Nexus_Certification.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          onclone: element => {
            const svgElements = Array.from(element.querySelectorAll('svg'))
            svgElements.forEach(s => {
              const bBox = s.getBBox()
              s.setAttribute('x', bBox.x)
              s.setAttribute('y', bBox.y)
              s.setAttribute('width', s.scrollWidth)
              s.setAttribute('height', s.scrollHeight)
            })
          }
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
      }

      html2pdf()
        .set(pdfOptions)
        .from(content)
        .toPdf()
        .output('blob')
        .then(pdf => {
          const pdfFile = new File([pdf], `Nexus_Certification.pdf`, { type: 'application/pdf' })
          setCertificate(pdfFile)
        })
    }
  }

  return (
    <SlideUpDialog
      id='certificateModal'
      fullScreen
      open={open}
      onClose={closeModal}
      title='Exam Certificate'
      actions={
        <RegularButton id='certificateCloseButton' onClick={() => closeModal()}>
          Done
        </RegularButton>
      }
    >
      <DialogContent>
        {certificate ? (
          <>
            <iframe
              src={previewURL}
              title='Certificate Preview'
              style={{ display: 'block', height: '80vh', width: '95vw', marginTop: '15px' }}
            />
          </>
        ) : (
          <>
            <div style={{ marginTop: '15px' }}>
              <CenteredDiv>
                <span>Certificate Preview</span>
              </CenteredDiv>
            </div>
            <Card sx={{ minHeight: '1000px', marginTop: '15px' }}>
              <CardContent sx={{ height: '100%', width: '100%', padding: '20px' }}>
                {
                  <CertificateTemplate
                    htmlRef={htmlRef}
                    certificateInfo={certificateInfo}
                    setLoaded={setLoaded}
                  ></CertificateTemplate>
                }
              </CardContent>
            </Card>
            <div style={{ marginTop: '15px' }}>
              <CenteredDiv>
                <PrimaryButton
                  id='certificateGenerateButton'
                  onClick={async () => {
                    await generatePdf()
                  }}
                >
                  Generate
                </PrimaryButton>
              </CenteredDiv>
            </div>
          </>
        )}
      </DialogContent>
    </SlideUpDialog>
  )
}
