import { Box, Icon, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ImageText } from '../ImageTextField'
import { WtxColors } from '@wavetronix/common-components'

export default function MultipleChoiceQuestionReview({ question, guid, showPoints }) {
  return (
    <>
      <div style={{ padding: '10px' }}>
        <h3>
          {question.pointValue} point{question.pointValue == 1 ? '' : 's'} earned
        </h3>
        <ImageText
          id={`${guid}AnswerTextField`}
          image={question.imageInfo ? question.imageInfo.image : null}
          text={question.prompt}
          isHeading={true}
          position={question.imageInfo ? question.imageInfo.position : null}
          imageComp={{ type: 'display' }}
        />
        {question.answerData
          ? Object.entries(question.answerData).map(([key, answer], index) => {
              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '15px'
                    }}
                  >
                    <div style={{ minWidth: '68px' }}>
                      {' '}
                      <label>{`${
                        answer.isUserAnswer ? `${answer.isCorrect ? 'Correct' : ''}` : `${answer.isCorrect ? '' : 'Correct'}`
                      }`}</label>
                    </div>
                    <Tooltip
                      title={
                        answer.isUserAnswer
                          ? `You checked this answer, ${answer.isCorrect ? 'and it is correct' : 'but it is incorrect'}.`
                          : `You did not check this answer, ${answer.isCorrect ? 'and it is incorrect' : 'but it is correct'}.`
                      }
                    >
                      <Icon sx={{ marginRight: '15px' }}>
                        {answer.isCorrect ? (
                          answer.isUserAnswer ? (
                            <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }} />
                          ) : (
                            <></>
                          )
                        ) : answer.isUserAnswer ? (
                          <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }} />
                        ) : (
                          <CheckIcon />
                        )}
                      </Icon>
                    </Tooltip>
                    <ImageText
                      i={`answer${index}ImageText`}
                      image={answer.imageInfo ? answer.imageInfo.image : null}
                      text={answer.answer}
                      position={answer.imageInfo ? answer.imageInfo.position : null}
                      imageComp={{ type: 'display' }}
                    />
                  </div>
                </Box>
              )
            })
          : null}
      </div>
    </>
  )
}
