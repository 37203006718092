export const localEnv = {
  runMode: 'dev',
  clientId: 'cb388c54-6b22-490c-a501-f79402008517',
  urls: {
    gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com',
    // gatekeeperURL: 'http://localhost:5001',
    examsURL: 'http://localhost:5071'
    // examsURL: 'https://certificationexams.wtxdev.com'
  },
  basicAuthentication: {}
}
