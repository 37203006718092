import * as React from 'react'
const WtxLogo = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    xmlSpace='preserve'
    style={{
      enableBackground: 'new 0 0 216 54'
    }}
    viewBox='0 0 216 54'
    {...props}>
    <style>{'.st1{fill:#3f454f}.st2{fill:#fff}'}</style>
    <symbol id='Wavetronix_brandmark_00000179634037409398680180000004557272765414762117_' viewBox='-9 -9 18 18'>
      <path
        d='M-9 9H2.25s-9-2.25-9-6.75C-6.75-4.5 9-7.88 9-7.88V-9H-9V9zM4.5 9s-7.88-2.25-7.88-5.62C-3.38-1.12 9-3.38 9-3.38V9H4.5z'
        style={{
          fill: '#ffca38'
        }}
      />
    </symbol>
    <g id='images'>
      <use
        xlinkHref='#Wavetronix_brandmark_00000179634037409398680180000004557272765414762117_'
        id='XMLID_1_'
        width={18}
        height={18}
        x={-9}
        y={-9}
        style={{
          overflow: 'visible'
        }}
        transform='matrix(1 0 0 -1 27 27)'
      />
      <use
        xlinkHref='#Wavetronix_brandmark_00000179634037409398680180000004557272765414762117_'
        width={18}
        height={18}
        x={-9}
        y={-9}
        style={{
          overflow: 'visible'
        }}
        transform='matrix(1 0 0 -1 27 117)'
      />
      <path
        d='M270 18h11.25s-9 2.25-9 6.75c0 6.75 15.75 10.12 15.75 10.12V36h-18V18zm13.5 0s-7.88 2.25-7.88 5.62c0 4.5 12.38 6.75 12.38 6.75V18h-4.5z'
        className='st1'
      />
      <path
        d='M270 108h11.25s-9 2.25-9 6.75c0 6.75 15.75 10.12 15.75 10.12V126h-18v-18zm13.5 0s-7.88 2.25-7.88 5.62c0 4.5 12.38 6.75 12.38 6.75V108h-4.5z'
        className='st2'
      />
    </g>
    <g id='text'>
      <path
        d='M61.57 35.99h-5.22l-3.06-13.66-3.09 13.66h-5.22L40.5 18.01h4.05l3.09 12.85 3.17-12.85h5l3.12 12.85 3.06-12.85h4.05l-4.47 17.98zM80.9 35.99h-4.26l-.98-3.51h-6.1l-.98 3.51h-4.26l5.55-17.98h5.47l5.56 17.98zm-10.41-6.88h4.18l-2.08-7.53-2.1 7.53zM89.98 35.99h-5.85l-5.14-17.98h4.21l3.85 14.31 3.85-14.31h4.21l-5.13 17.98zM96.76 35.99V18.01h11.45v3.59h-7.35v3.29h6.78v3.59h-6.78v3.91h7.35v3.59H96.76zM118.77 21.6v14.39h-4.1V21.6h-4.89v-3.59h13.88v3.59h-4.89zM155.68 30.75c0 2.73-1.91 5.51-7.05 5.51s-7.05-2.78-7.05-5.51v-7.5c0-2.73 1.91-5.51 7.05-5.51s7.05 2.78 7.05 5.51v7.5zm-4.1-7.15c0-1.4-.9-2.27-2.95-2.27s-2.95.86-2.95 2.27v6.8c0 1.4.9 2.27 2.95 2.27s2.95-.86 2.95-2.27v-6.8zM162.19 24.17v11.82h-3.91V18.01h5.03l5.99 12.39V18.01h3.91v17.98h-5.06l-5.96-11.82zM176.49 35.99V18.01h4.1v17.98h-4.1zM192.48 26.87l5.52 9.12h-4.29l-3.53-5.94-3.53 5.94h-4.29l5.49-9.12-5.3-8.85h4.29l3.33 5.67 3.33-5.67h4.29l-5.31 8.85zM136.21 29.83c1.83-.76 2.65-2.27 2.65-4.51v-2.21c0-3.37-1.64-5.1-6.07-5.1h-7.57v17.98h4.1V21.6h3.25c1.67 0 2.19.54 2.19 1.89v1.43c0 1.35-.44 1.97-2.19 1.97h-.86l.01 3.51 4.02 5.59h4.87l-4.4-6.16z'
        className='st1'
      />
      <path
        d='M61.57 125.99h-5.22l-3.06-13.66-3.09 13.66h-5.22l-4.48-17.98h4.04l3.09 12.85 3.17-12.85h5l3.12 12.85 3.06-12.85h4.05l-4.46 17.98zM80.9 125.99h-4.26l-.98-3.51h-6.1l-.98 3.51h-4.26l5.55-17.98h5.47l5.56 17.98zm-10.41-6.88h4.18l-2.08-7.53-2.1 7.53zM89.98 125.99h-5.85l-5.14-17.98h4.21l3.85 14.31 3.85-14.31h4.21l-5.13 17.98zM96.76 125.99v-17.98h11.45v3.59h-7.35v3.29h6.78v3.59h-6.78v3.91h7.35v3.59H96.76zM118.77 111.6v14.39h-4.1V111.6h-4.89v-3.59h13.88v3.59h-4.89zM155.68 120.75c0 2.73-1.91 5.51-7.05 5.51s-7.05-2.78-7.05-5.51v-7.5c0-2.73 1.91-5.51 7.05-5.51s7.05 2.78 7.05 5.51v7.5zm-4.1-7.15c0-1.4-.9-2.27-2.95-2.27-2.05 0-2.95.87-2.95 2.27v6.8c0 1.4.9 2.27 2.95 2.27 2.05 0 2.95-.86 2.95-2.27v-6.8zM162.19 114.17v11.82h-3.91v-17.98h5.03l5.99 12.39v-12.39h3.91v17.98h-5.06l-5.96-11.82zM176.49 125.99v-17.98h4.1v17.98h-4.1zM192.48 116.87l5.52 9.12h-4.29l-3.53-5.94-3.53 5.94h-4.29l5.49-9.12-5.3-8.85h4.29l3.34 5.67 3.33-5.67h4.29l-5.32 8.85zM136.21 119.83c1.83-.76 2.65-2.27 2.65-4.51v-2.21c0-3.37-1.64-5.1-6.07-5.1h-7.57v17.98h4.1V111.6h3.25c1.67 0 2.19.54 2.19 1.89v1.43c0 1.35-.44 1.97-2.19 1.97h-.86l.01 3.51 4.02 5.59h4.87l-4.4-6.16z'
        className='st2'
      />
      <path
        d='M313.57 35.99h-5.22l-3.06-13.66-3.09 13.66h-5.22l-4.48-17.98h4.04l3.09 12.85 3.17-12.85h5l3.12 12.85 3.06-12.85h4.05l-4.46 17.98zM332.9 35.99h-4.26l-.98-3.51h-6.1l-.98 3.51h-4.27l5.55-17.98h5.47l5.57 17.98zm-10.41-6.88h4.18l-2.08-7.53-2.1 7.53zM341.98 35.99h-5.85l-5.14-17.98h4.21l3.85 14.31 3.86-14.31h4.21l-5.14 17.98zM348.76 35.99V18.01h11.45v3.59h-7.35v3.29h6.78v3.59h-6.78v3.91h7.35v3.59h-11.45zM370.77 21.6v14.39h-4.1V21.6h-4.89v-3.59h13.88v3.59h-4.89zM407.68 30.75c0 2.73-1.91 5.51-7.05 5.51s-7.05-2.78-7.05-5.51v-7.5c0-2.73 1.91-5.51 7.05-5.51s7.05 2.78 7.05 5.51v7.5zm-4.1-7.15c0-1.4-.9-2.27-2.95-2.27-2.05 0-2.95.87-2.95 2.27v6.8c0 1.4.9 2.27 2.95 2.27 2.05 0 2.95-.86 2.95-2.27v-6.8zM414.19 24.17v11.82h-3.91V18.01h5.03l5.99 12.39V18.01h3.91v17.98h-5.06l-5.96-11.82zM428.49 35.99V18.01h4.1v17.98h-4.1zM444.48 26.87l5.52 9.12h-4.29l-3.53-5.94-3.53 5.94h-4.29l5.49-9.12-5.3-8.85h4.29l3.34 5.67 3.33-5.67h4.29l-5.32 8.85zM388.21 29.83c1.83-.76 2.65-2.27 2.65-4.51v-2.21c0-3.37-1.64-5.1-6.07-5.1h-7.57v17.98h4.1V21.6h3.25c1.67 0 2.19.54 2.19 1.89v1.43c0 1.35-.44 1.97-2.19 1.97h-.86l.01 3.51 4.02 5.59h4.87l-4.4-6.16z'
        className='st1'
      />
      <path
        d='M313.58 125.99h-5.22l-3.06-13.66-3.09 13.66h-5.22l-4.48-17.98h4.04l3.09 12.85 3.17-12.85h5l3.12 12.85 3.06-12.85h4.05l-4.46 17.98zM332.9 125.99h-4.26l-.98-3.51h-6.1l-.98 3.51h-4.27l5.55-17.98h5.47l5.57 17.98zm-10.41-6.88h4.18l-2.08-7.53-2.1 7.53zM341.98 125.99h-5.85l-5.14-17.98h4.21l3.85 14.31 3.86-14.31h4.21l-5.14 17.98zM348.76 125.99v-17.98h11.45v3.59h-7.35v3.29h6.78v3.59h-6.78v3.91h7.35v3.59h-11.45zM370.77 111.6v14.39h-4.1V111.6h-4.89v-3.59h13.88v3.59h-4.89zM407.68 120.75c0 2.73-1.91 5.51-7.05 5.51s-7.05-2.78-7.05-5.51v-7.5c0-2.73 1.91-5.51 7.05-5.51s7.05 2.78 7.05 5.51v7.5zm-4.1-7.15c0-1.4-.9-2.27-2.95-2.27-2.05 0-2.95.87-2.95 2.27v6.8c0 1.4.9 2.27 2.95 2.27 2.05 0 2.95-.86 2.95-2.27v-6.8zM414.19 114.17v11.82h-3.91v-17.98h5.03l5.99 12.39v-12.39h3.91v17.98h-5.06l-5.96-11.82zM428.49 125.99v-17.98h4.1v17.98h-4.1zM444.48 116.87l5.52 9.12h-4.29l-3.53-5.94-3.53 5.94h-4.29l5.49-9.12-5.3-8.85h4.29l3.34 5.67 3.33-5.67h4.29l-5.32 8.85zM388.21 119.83c1.83-.76 2.65-2.27 2.65-4.51v-2.21c0-3.37-1.64-5.1-6.07-5.1h-7.57v17.98h4.1V111.6h3.25c1.67 0 2.19.54 2.19 1.89v1.43c0 1.35-.44 1.97-2.19 1.97h-.86l.01 3.51 4.02 5.59h4.87l-4.4-6.16z'
        className='st2'
      />
    </g>
  </svg>
)
export default WtxLogo
