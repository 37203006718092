import {
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
  Dialog,
  CircularProgress,
  Card,
  CardContent,
  DialogActions
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { CenteredDiv, CustomSelect, PrimaryButton, getResourceUrl, DeleteButton } from '@wavetronix/common-components'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import NexusAssetsApi from '../api/NexusAssetsApi'
import { env } from '../index.js'
import ImageSelect from './ImageSelect'

export const DEFAULT_IMAGEINFO = {
  position: 'Bottom',
  image: ''
}

export default function ImageTextField({ imageInfo, setImageInfo, value, multiline, id, ...props }) {
  const [imageDialogState, setImageDialogState] = useState(false)

  return (
    <>
      <ImageSelectDialog
        open={imageDialogState}
        onClose={() => setImageDialogState(false)}
        value={value}
        imageInfo={imageInfo}
        setImageInfo={setImageInfo}
      />
      <TextField
        id={id}
        InputProps={{
          sx: { paddingRight: '0px' },
          endAdornment: (
            <InputAdornment position='end' sx={multiline ? { marginTop: '10px', marginBottom: 'auto' } : {}}>
              <IconButton onClick={() => setImageDialogState(true)}>
                <AddPhotoAlternateIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </>
  )
}

/*
imageComp - object: 
  - type: string - 'select' or 'display'
  - assets: string[] - can be empty
  - isLoading: bool
  - component: component to display (either img or ImageSelect)
*/

export function ImageText({ image, text, isHeading = false, position, imageComp, showBlankImage = false }) {
  return (
    <>
      {
        {
          Top: (
            <div>
              <div style={{ marginBottom: '15px' }}>
                <CenteredDiv>
                  {imageComp.type === 'select' ? (
                    imageComp.component
                  ) : image !== '' ? (
                    <img
                      src={`${getResourceUrl(env.runMode)}/images/exams/${image}`}
                      alt='Preview'
                      style={{
                        maxHeight: 300,
                        maxWidth: 300,
                        marginBottom: '15px'
                      }}
                    />
                  ) : showBlankImage === true ? (
                    <img
                      src={'images/blankimage.svg'}
                      alt='Preview'
                      style={{
                        maxHeight: 300,
                        maxWidth: 300,
                        marginBottom: '15px'
                      }}
                    />
                  ) : null}
                </CenteredDiv>
              </div>
              <CenteredDiv>{isHeading ? <h3 style={{ marginTop: 0 }}>{text}</h3> : text}</CenteredDiv>
            </div>
          ),
          Right: (
            <div style={{ display: 'flex' }}>
              <div>{isHeading ? <h3 style={{ marginTop: 0 }}>{text}</h3> : text}</div>
              <div style={{ marginLeft: '15px' }}>
                {imageComp.type === 'select' ? (
                  imageComp.component
                ) : image !== '' ? (
                  <img
                    src={`${getResourceUrl(env.runMode)}/images/exams/${image}`}
                    alt='Preview'
                    style={{
                      maxHeight: 300,
                      maxWidth: 300,
                      marginBottom: '15px'
                    }}
                  />
                ) : showBlankImage === true ? (
                  <img
                    src={'images/blankimage.svg'}
                    alt='Preview'
                    style={{
                      maxHeight: 300,
                      maxWidth: 300,
                      marginBottom: '15px'
                    }}
                  />
                ) : null}
              </div>
            </div>
          ),

          Bottom: (
            <div>
              <CenteredDiv>{isHeading ? <h3 style={{ marginTop: 0 }}>{text}</h3> : text}</CenteredDiv>
              <div style={{ marginTop: '15px' }}>
                <CenteredDiv>
                  {imageComp.type === 'select' ? (
                    imageComp.component
                  ) : image !== '' ? (
                    <img
                      src={`${getResourceUrl(env.runMode)}/images/exams/${image}`}
                      alt='Preview'
                      style={{
                        maxHeight: 300,
                        maxWidth: 300,
                        marginBottom: '15px'
                      }}
                    />
                  ) : showBlankImage === true ? (
                    <img
                      src={'images/blankimage.svg'}
                      alt='Preview'
                      style={{
                        maxHeight: 300,
                        maxWidth: 300,
                        marginBottom: '15px'
                      }}
                    />
                  ) : null}
                </CenteredDiv>
              </div>
            </div>
          ),
          Left: (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '15px' }}>
                {imageComp.type === 'select' ? (
                  imageComp.component
                ) : image !== '' ? (
                  <img
                    src={`${getResourceUrl(env.runMode)}/images/exams/${image}`}
                    alt='Preview'
                    style={{
                      maxHeight: 300,
                      maxWidth: 300,
                      marginBottom: '15px'
                    }}
                  />
                ) : showBlankImage === true ? (
                  <img
                    src={'images/blankimage.svg'}
                    alt='Preview'
                    style={{
                      maxHeight: 300,
                      maxWidth: 300,
                      marginBottom: '15px'
                    }}
                  />
                ) : null}
              </div>
              <div>{isHeading ? <h3 style={{ marginTop: 0 }}>{text}</h3> : text}</div>
            </div>
          ),
          '': (
            <CenteredDiv>
              <CircularProgress />
            </CenteredDiv>
          )
        }[position]
      }
    </>
  )
}

function ImageSelectDialog(imageInfo, onClose, setImageInfo, open, value, ...props) {
  const { instance, accounts } = useMsal()
  const [position, setPosition] = useState('')
  const [asset, setAsset] = useState('')

  const { data: assetsData, isLoading: assetsLoading } = useQuery(
    'assets',
    async () => await NexusAssetsApi.getAssets(instance, accounts)
  )

  useEffect(() => {
    if (imageInfo) {
      setPosition(imageInfo.position)
      setAsset(imageInfo.image)
    }
  }, [imageInfo])

  function closeModal() {
    if (imageInfo) {
      setPosition(imageInfo.position)
      setAsset(imageInfo.image)
    }
    onClose()
  }

  function saveInfo() {
    setImageInfo({
      position: position,
      image: asset
    })
    onClose()
  }

  function removeInfo() {
    setPosition(DEFAULT_IMAGEINFO.position)
    setAsset(DEFAULT_IMAGEINFO.image)
    setImageInfo(DEFAULT_IMAGEINFO)
    onClose()
  }

  return (
    <Dialog id='imageSelectDialog' open={open} onClose={closeModal} maxWidth='md' fullWidth>
      <DialogContent sx={{ minHeight: '400px' }}>
        <CustomSelect
          id='imagePositionSelect'
          label='Position'
          options={['Top', 'Right', 'Bottom', 'Left']}
          style={{ width: '100%' }}
          value={position}
          onChange={e => setPosition(e.target.value)}
        />

        <Card sx={{ marginTop: '15px', minHeight: '800px' }}>
          <CardContent>
            <ImageText
              image={asset}
              text={value}
              position={position}
              showBlankImage={true}
              imageComp={{
                type: 'select',
                assets: assetsData,
                isLoading: assetsLoading,
                component: (
                  <ImageSelect
                    onChange={value => setAsset(value)}
                    value={asset}
                    srcPath={`${getResourceUrl(env.runMode)}/images/exams`}
                    label={'Change Image'}
                    options={assetsData}
                    env={env.runMode}
                    folder={'exams'}
                    imageStyle={{ maxHeight: '300px', maxWidth: '100%' }}
                  />
                )
              }}
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <DeleteButton id='removeImageButton' onClick={removeInfo}>
          Remove
        </DeleteButton>

        <PrimaryButton id='saveImageButton' onClick={saveInfo}>
          Save
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}
