import { acquireAccessToken } from '@wavetronix/common-components'
import { env } from '../index.js'
import axios from 'axios'

let instance = null

class EmailApi {
  passExam = async (msalInstance, accounts, examInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(`${env.urls.examsURL}/api/emails/passexam`, examInfo, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
  failExam = async (msalInstance, accounts, examInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(`${env.urls.examsURL}/api/emails/failexam`, examInfo, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
  lockExam = async (msalInstance, accounts, examInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(`${env.urls.examsURL}/api/emails/lockexam`, examInfo, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new EmailApi()
  }
  return instance
}

export default getInstance()
