import { createWtxAuthInstance, english, french, CenteredDiv, WtxColors, SetupProvider } from '@wavetronix/common-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LocalizedStrings from 'react-localization'
import { createRoot } from 'react-dom/client'
import axios from 'axios'
import React from 'react'
import './index.css'
import generateEnv from './env/generateEnv.js'
import MainPage from './components/MainPage'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()
const root = createRoot(document.getElementById('root'))

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)
  const queryClient = new QueryClient()
  const localizedStrings = new LocalizedStrings({ en: { ...english }, fr: { ...french } })

  let lang = window.navigator.language
  let langCode = ''

  if (lang.includes('-') && lang.length > 2) {
    langCode = lang.substring(0, 2)
  } else if (lang.length === 2) {
    langCode = lang
  } else {
    langCode = 'en'
  }

  localizedStrings.setLanguage(langCode)
  // document.getElementById('title').innerHTML = 'Certification Exam'

  root.render(
    <SetupProvider
      env={env}
      allowedRoles={[]}
      msalInstance={msalInstance}
      languages={{
        en: localizedStrings.en,
        fr: localizedStrings.fr
      }}>
      <QueryClientProvider client={queryClient}>
        <MainPage />
      </QueryClientProvider>
    </SetupProvider>
  )
}
