import { useEffect } from 'react'
// import { ImageText } from '../ImageTextField'
// import { Grid, TextField } from '@mui/material'
// import ReorderField from '../ReorderField'

export default function MatchingQuestion({ prompt, imageInfo, leftOptions, rightOptions, index, answerKey, setAnswer }) {
  useEffect(() => {
    if (rightOptions) rightOptions.map(e => ({ ...e, type: '' }))
  }, [rightOptions])

  // const reorderAnswers = (oldIndex, newIndex) => {
  //   console.log(rightOptions[oldIndex])
  //   console.log(rightOptions[newIndex])
  // }

  return (
    <>
      <div style={{ width: '100%', padding: '10px' }}>
        <h3>Matching questions are under construction. This question will not be graded.</h3>
        {/* 
          <h3>{question.pointValue} points</h3>
          <h3>{prompt}</h3>
        <ImageText
          image={imageInfo ? imageInfo.image : null}
          text={prompt}
          position={imageInfo ? imageInfo.position : null}
          imageComp={{ type: 'display' }}
        />
        <div key={index} style={{ display: 'flex' }}>
          <Grid container sx={{ minWidth: '100%' }}>
            <Grid item lg={4} sx={{ marginRight: '36px' }}>
              <div>
                {leftOptions ? (
                  leftOptions.map((choice, index) => {
                    return (
                      <div style={{ margin: '20px' }}>
                        <span>{`${index + 1}. ${choice.answer.leftMatch}`}</span>
                      </div>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
              <div style={{ width: 'auto' }}>
                {leftOptions
                  ? leftOptions.map((opt, index) => (
                      <ImageText
                        key={index}
                        image={opt.answer.leftImageInfo ? opt.answer.leftImageInfo.image : null}
                        text={opt.answer.leftMatch}
                        position={opt.answer.leftImageInfo ? opt.answer.leftImageInfo.position : null}
                        imageComp={{ type: 'display' }}
                      />
                    ))
                  : []}
              </div>
            </Grid>
            <Grid item lg={6}>
              <div>
                {rightOptions ? (
                  rightOptions.map((choice, index) => {
                    return (
                      <div
                        style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center', margin: '8px' }}>
                        <ReorderField number={index} reorder={reorderAnswers}></ReorderField>
                        <span>{`${choice.answer.rightMatch}`}</span>
                      </div>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
              <div style={{ width: '50%' }}>
                {rightOptions
                  ? rightOptions.map((opt, index) => (
                      <ImageText
                        key={index}
                        image={opt.answer.rightImageInfo ? opt.answer.rightImageInfo.image : null}
                        text={opt.answer.rightMatch}
                        position={opt.answer.rightImageInfo ? opt.answer.rightImageInfo.position : null}
                        imageComp={{ type: 'display' }}
                      />
                    ))
                  : []}
              </div>
            </Grid>
          </Grid>
        </div> */}
      </div>
    </>
  )
}
