import ExamPage from './ExamPage'
import SubmittedPage from './SubmittedPage'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { CssBaseline } from '@mui/material'
import { env } from '../index.js'
import version from '../env/version.json'
import { SnackbarProvider } from 'notistack'

function MainPage() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CssBaseline />
      <SnackbarProvider>
        <MenuAppBar env={env} appHeader='Technical Training Exam' />
      </SnackbarProvider>
      <BrowserRouter>
        <div className='content' style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <Routes>
              <Route path='/' element={<ExamPage />} />
              <Route path='/version' element={<div>{version.version}</div>} exact />
              <Route
                path='/submitted'
                element={
                  <div className='content' style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                      <SubmittedPage />
                    </div>
                  </div>
                }
                exact
              />
              {/* This wildcard route needs to stay at the bottom */}
              <Route path='/*' element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default MainPage
