import { acquireAccessToken } from '@wavetronix/common-components'
import { env } from '../index.js'
import axios from 'axios'

let instance = null

class ExamsApi {
  getExams = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.examsURL}/exam`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Exams Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Exam may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getExam = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.examsURL}/exam/${id}`, { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 })
      .then(result => result.data)
  }

  getUserExamRecord = async (msalInstance, accounts, userId, examId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.examsURL}/record/?userId=${userId}&examId=${examId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  submitExam = async (msalInstance, accounts, recordId, newRecord) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.put(`${env.urls.examsURL}/record${recordId}`, newRecord, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  updateRecord = async (msalInstance, accounts, examInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .put(`${env.urls.examsURL}/record/${examInfo.id}`, examInfo, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new ExamsApi()
  }
  return instance
}

export default getInstance()
